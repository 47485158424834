@import url('/src/styles/spacing.css');
@import url('/src/styles/coloring.css');
@import url('/src/styles/layout.css');

.flex-center-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mb-0 {
  margin-bottom: 0;
}

.m0 {
  margin: 0;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h4 {
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0em;
  color: rgb(107, 116, 131);
}

p {
  font-size: 12px;
}

.select-offset .ant-select-selection-item {
  margin-left: 16px;
}

.card {
  background-color: white;
  border-radius: 16px;
}

.insight-card {
  padding: 8px;
  margin: 8px;
}

.flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.pointer {
  cursor: pointer;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  /* Light grey */
  border-top: 10px solid #383636;
  /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}