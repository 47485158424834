.row {
    flex-direction: row;
}

.row-r {
    flex-direction: row-reverse;
}

.flex-r {
    display: flex;
    flex-direction: row-reverse;
}

.col {
    display: flex;
    flex-direction: column;
}

.col-r {
    display: flex;
    flex-direction: column-reverse;
}

.flex {
    display: flex;
    flex-direction: row;
}

.justify-between {
    display: flex;
    justify-content: space-between;
}

.justify-around {
    display: flex;
    justify-content: space-around;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-start {
    display: flex;
    align-items: flex-start;
}

.flex-center-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-center-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-center-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-center-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.flex-center-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-start-center {
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.flex-start-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-start-end {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.flex-start-around {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
}

.flex-start-between {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.flex-end-center {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.flex-end-start {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
}

.flex-end-end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.flex-end-around {
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

.flex-end-between {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.z-1 {
    z-index: -100;
}

.z0 {
    z-index: 0;
}

.z1 {
    z-index: 100;
}

.z2 {
    z-index: 200;
}

.z3 {
    z-index: 300;
}

.z4 {
    z-index: 400;
}

.z5 {
    z-index: 500;
}

.z6 {
    z-index: 600;
}

.z7 {
    z-index: 700;
}

.z8 {
    z-index: 800;
}

.z9 {
    z-index: 900;
}

.z10 {
    z-index: 1000;
}

.z11 {
    z-index: 1100;
}

.ant-card-body {
    padding: 16px !important;
}

.w25 {
    width: 25%;
}

.w100 {
    width: 100%;
}

.h300px {
    height: 300px;
}

.border-r {
    border-right: solid #F0F2F5 1px;
}

.border {
    border: solid #F0F2F5 1px;
}

.h3 {
    display: block;
    font-size: 1.07em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

#insightsView p {
    color: #6B7483;
}

#insightsView .active-bg p {
    color: #ffffff;
}

.front,
.back {
    height: 300px;
    width: 100%;
    overflow: hidden;
    backface-visibility: hidden;
    position: absolute;
    transition: transform .6s linear;
}

.front {
    transform: perspective(600px) rotateY(0deg);
}

.back {
    transform: perspective(600px) rotateY(-180deg);
}

.card.rotated>.front {
    transform: perspective(600px) rotateY(180deg);
}

.card.rotated>.back {
    transform: perspective(600px) rotateY(0deg);
}

.ant-slider-handle::before {
    content: "";
    position: absolute;
    inset-inline-start: -2px !important;
    inset-block-start: -2px !important;
    width: 20px !important;
    height: 20px !important;
    background-color: transparent;
}

.ant-slider-handle::after {
    content: "";
    position: absolute;
    inset-block-start: 2px !important;
    inset-inline-start: -12px !important;
    width: 36px !important;
    height: 24px !important;
    background-color: #055FFC !important;
    box-shadow: 0 0 0 2px #0099FF !important;
    border-radius: 16px !important;
    cursor: pointer;
    transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s, height 0.2s, box-shadow 0.2s;
}

.datetimeinput .form-control {
    width: 91% !important;
    border-radius: 4px !important;
    height: 26px !important;
    border-color: #D3D3D3 !important;
    border-top-color: #D3D3D3 !important;
    border-width: 1px !important;
    box-shadow: none !important;
    outline: none !important;
    padding-left: 8px;
    padding-right: 8px;
}

.none .rdtPicker {
    display: none !important;
}

.datetimeinput .rdtPicker {
    position: absolute;
    z-index: 200;
    background-color: #ffffff;
    padding: 8px;
    border-radius: 16px;
    left: 6px;
    width: 210px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.datetimeinput .rdtPicker table {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
}

.datetimeinput .rdtPicker table .rdtNext {
    cursor: pointer !important;
}

.datetimeinput .rdtPicker table .rdtPrev {
    cursor: pointer !important;
}

.datetimeinput .rdtPicker table .rdtSwitch {
    cursor: pointer !important;
}

.datetimeinput .rdtPicker table tfoot {
    font-weight: 600;
    font-size: 16px;
}

.datetimeinput .rdtPicker table .rdtDay {
    text-align: center !important;
    cursor: pointer !important;
}

.datetimeinput .rdtPicker table .rdtDay:hover {
    text-align: center;
    background-color: #0099FF;
    cursor: pointer !important;
    color: #fff;
}

.datetimeinput .rdtPicker table .rdtActive {
    text-align: center;
    background-color: #3366CC;
    cursor: pointer !important;
    color: #fff;
}

.datetimeinput .rdtPicker .rdtTime .rdtCounters {
    display: flex;
    flex-direction: row !important;
    justify-content: space-around;
    width: 75%;
}

.datetimeinput .rdtPicker .rdtTime .rdtCounter {
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.datetimeinput .rdtPicker .rdtTime .rdtBtn {
    cursor: pointer !important;
}

.datetimeinput .rdtPicker .rdtTime .rdtCounterSeparator {
    margin-right: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}