.parent {
    position: relative;
}

.child {
    position: absolute;
    transform: translate(-50%, -50%);
}

.tooltipMenu {
    border: 1px solid #ccc;
}

.parent:hover .tooltip,
.tooltip:hover {
    display: block;
}